
import '../../css/app/components/home.scss';
class Home
{
    constructor ()
    {
        console.log('Home initialised');
    }
}

new Home();
